import { Component,Renderer2} from '@angular/core';
import { MessagingService } from '@moduurnv2/web/core';
import { ReviewPayBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
import { Store, Select } from '@ngxs/store';
import { Router,ActivatedRoute } from '@angular/router';

import {
  SweetAlertService,
  WhitelabelState,
  VenueManagementService,
  DeliveryState,
  SetPaymentSelected,
  LocationDefaultState,
  AnalyticsTrackingType,
  PurchaseAnalytics,
  setAnalyticsData,
  PaymentMethodState
} from '@moduurnv2/libs-orderingapp/src/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { Location } from '@angular/common';
import {AddressWrapperComponent} from './../../features/shared/address-wrapper/address-wrapper.component'
import { CurrencyLocalize } from '@moduurnv2/features/ui/pipes';
import { PaymentMethodModel } from '@moduurnv2/libs-orderingapp/src/core/state/payment-method.state';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { SetLoginFrom } from '@moduurnv2/libs-orderingapp/src';
import { AnalyticsHandler } from '@moduurnv2/core/services';
@Component({
  selector: 'moduurnv2-review-and-pay',
  templateUrl: './review-and-pay.component.html',
  styleUrls: ['./review-and-pay.component.scss'],
  providers: [CurrencyLocalize]
})
export class ReviewAndPayComponent extends ReviewPayBaseComponent
   {
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    // appFontFamily: true,
  };
  @Select(PaymentMethodState.getPaymentState) paymentState$: Observable<
    PaymentMethodModel
  >;
  isMobile: boolean;
  paymentState: PaymentMethodModel;
  constructor(
    public store: Store,
    public analyticsHandler: AnalyticsHandler,
    public router: Router,
    public sweetAlert: SweetAlertService,
    public messagingService: MessagingService,
    public spinner: NgxSpinnerService,
    public shared: SharedService,
    public translate: TranslateService,
    public _location: Location,
    venueManagementService: VenueManagementService,
    modal: ModalService,
    private currency : CurrencyLocalize,
    private route : ActivatedRoute,
    renderer2:Renderer2
  ) {
    super(
      store,
      router,
      sweetAlert,
      spinner,
      shared,
      translate,
      venueManagementService,
      modal,
      _location,
      renderer2
    );
    this.dealActions();
    this.getShifts();
    this.route.queryParams.subscribe(params => {
      // to route to customer info entry page once payment method is selected. paymentId is send from payment selection screen in mobile
      if(params?.paymentId){
        console.log('selected paymentId ->',params.paymentId)
        this.router.navigate([`/order/complete`]);
      }
    });
    
  }
  async checkAndPlaceOrder() {
    this.store.dispatch(new SetPaymentSelected(true));
    this.store.dispatch( new SetLoginFrom("reviewPage"))
    if(!this.checkOrderLevelModifiers()){
      return   this.sweetAlert.toast('error', 'Sorry, Please select the required items to continue.');
    }
    let vm = this;
    let notification = localStorage.getItem('PushNotification')
      ? JSON.parse(localStorage.getItem('PushNotification'))
      : undefined;
    let otherConfigs = this.store.selectSnapshot(WhitelabelState.getOther);
    let bypassCustomerDetailsEntry = otherConfigs.bypassCustomerDetailsEntry
      ? otherConfigs.bypassCustomerDetailsEntry
      : false;
    const { minimumCharge, subTotal } = this.cartPrice;

    if(minimumCharge && subTotal < minimumCharge)
      return this.sweetAlert.simpleAlert('Warning', `A minimum of ${this.currency.transform(minimumCharge)} is required to process this order.`);
    if(this.validateDeliveryLocation()){
      if (
      this.messagingService.checkForNotificationPopup() &&
      notification != 'denied' &&
      !bypassCustomerDetailsEntry
    ) {
      await this.sweetAlert
        .allowPushNotification(
          this.translate.instant('order-update'),
          this.translate.instant('update-order-status'),
          true,
          this.translate.instant('allow'),
          this.translate.instant('deny'),
        )
        .then((choice) => {
          if (choice) {
            return vm.messagingService.askPermission().then((response) => {
              vm.placeOrder();
            });
          } else {
            localStorage.setItem('PushNotification', JSON.stringify('denied'));
            vm.placeOrder();
          }
        });
    } else {
      vm.placeOrder();
    }
  }
  this.analyticsHandler.setEvents("Transactions", "Ecommerce", "Transactions",  otherConfigs._id, otherConfigs);
  }

  trackAnalyticsPurchase(){
    // console.log("purchase:", purchase)
    if(location){

      const purchaseAnalytics: PurchaseAnalytics = {
        _id: this.paymentState?.customerProfileId,
        amount: this.cartPrice?.price,
        analyticsTrackingType: AnalyticsTrackingType.PURCHASE,
        paymentType:this.selectedPaymentType,
        totalGross:this.cartPrice?.taxes,
        totalNet:this.cartPrice?.subTotal,
        tips:this.cartPrice?.tip,
      };
      console.log("purchaseAnalytics:", purchaseAnalytics)
    this.store.dispatch(new setAnalyticsData(purchaseAnalytics));}
  }

  get grandTotal() {
    if (this.cartPrice.tip) return this.cartPrice.price + this.cartPrice.tip;
    return this.cartPrice.price;
  }

  validateDeliveryLocation() {
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
      if(delivery && (delivery.textCode =='DELIVERYTYPE01' || delivery.textCode =='DELIVERYTYPE04')){
        // let selectedAddress = this.store.selectSnapshot(AddressState.getDeliveryAddress);
        let selectedAddress =
              localStorage.getItem('selectedAdrsLocation') &&
              localStorage.getItem('selectedAdrsLocation') != 'undefined'
                ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
                : undefined;
        if(selectedAddress)
          return true;
        else {
          this.forceUserSelectedDeliveryAddress(delivery.textCode);
          return false;
        }
      } else {
        return true;
      }
  }

  forceUserSelectedDeliveryAddress(deliveryType) {
    if(deliveryType =='DELIVERYTYPE01' || deliveryType =='DELIVERYTYPE04')
    // this.store.dispatch(new ChangePopupStatus(true));
    
   

    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobile = isMobileView)
    );
    if (this.isMobile) {
      this.modal.showSmallModal(AddressWrapperComponent);
    } else {
      this.modal.showMediumModalDesktop(AddressWrapperComponent);
    }
  }
}
