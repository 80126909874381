// angular
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

//rxjs
import { Observable, Subscription} from 'rxjs';

// app
import { environment } from '../../core/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OrderReceiptService {
  constructor(
    private http: HttpClient, private translate: TranslateService
  ) { }

  getOrderReceiptDetails(orderId:string):Observable<any>{
    const url = `${environment.locationApiUrl}orders/${orderId}/receiptDetails`;
    return this.http.get(url);
  }



  fetchReciept(dataForReceipt){
    let currentLanguage = this.translate.currentLang;
    let url = `${environment.customerApiUrl}customers/${dataForReceipt.order._id}/print`;
    if(currentLanguage != 'en')
    {
    if(currentLanguage == 'fr')
      currentLanguage = 'LANGUAGE01';      
      else if(currentLanguage == 'es')
      currentLanguage = 'LANGUAGE02';
      url+='?lang='+currentLanguage;
    }
    return this.http.post(url, dataForReceipt, { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }), responseType: 'text' })
  }


  fetchServiceOrg():Observable<any> {
    const url = environment.organizationApiUrl + 'organizations/000000000000000000000000/configuration';
    return this.http.get(url);
  }
}
