import { Component, Input } from '@angular/core';
import { ReviewPayBaseComponent } from '@moduurnv2/libs-orderingapp/src';

@Component({
    selector: 'moduurnv2-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent extends ReviewPayBaseComponent {
    @Input() messageId: string;
}
