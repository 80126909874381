import {
  BaseComponent,
  FetchViewAllOrdersList,
  ViewAllOrdersState,
  AuthState,
  FetchInProgressOrderList,
  SelectedOrderState,
  AuthEmailState,
  OrderState,
  changeShowStatus,
  fromOrderSuccess,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Select, Store } from '@ngxs/store';
import { Router, ActivatedRoute } from '@angular/router';
import { OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { takeUntil } from 'rxjs/operators';

export class ViewAllOrdersBaseComponent extends BaseComponent
  implements OnInit {
  loggedInUserDetails: any;
  viewOrderHeaderImg: string =
    environment.image_basepath + environment.images.view_order_header;
  public viewOrdersAll = [];
  public inProgressOrders = [];
  orderDetails: any;
  isActive: number = 1;
  viewAllOrderSubscription: Subscription;
  inProgressOrderSubscription: Subscription;
  isLoggedIn: any = false;
  isLoggedInSubscription: Subscription;
  IsFromOrderSuccess: boolean = false;

  @Select(ViewAllOrdersState.getViewAllOrdersList)
  viewAllOrdersList$: Observable<any>;
  @Select(ViewAllOrdersState.getInProgressOrderList)
  inProgressOrderList$: Observable<any>;
  @Select(AuthEmailState.isLoggedIn)
  isUserLoggedIn$: Observable<any>;
  @Select(OrderState.getShowstatus) getStatus$: Observable<boolean>;
  @Select(OrderState.getOrderSuccess) getIsSuccessPage$: Observable<boolean>;
  @Input('showStatus') showStatus: boolean;

  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
    if (this.orderDetails) this.selectStatusCategory(1);

    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel){
      let image = whitelabel.ordersPage && whitelabel.ordersPage.desktop? whitelabel.ordersPage.desktop : environment.images.view_order_header;
      this.viewOrderHeaderImg = environment.imagebaseurl + image;
    }
  }

  ngOnInit() {
    this.subscribeStatus();
    this.subscribeIsSuccessPage();
    this.loggedInUserDetails = localStorage.getItem('loggedInUser')
      ? JSON.parse(localStorage.getItem('loggedInUser'))
      : undefined;

    this.isUserLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const setToPastOrdersFromRoute: string = this.route.snapshot.queryParamMap ? this.route.snapshot.queryParamMap.get('orders') : '';
        if( response && setToPastOrdersFromRoute && setToPastOrdersFromRoute == "past" ){
          this.isActive = 3;
        }
        this.isLoggedIn = response;
        if (this.isLoggedIn) {
         
          let payload = {};
          const loggedInUser = this.store.selectSnapshot(
            AuthState.getCommonAuthDetails
          );
          payload['custId'] = loggedInUser.customerId;
          payload['orgId'] = Config.organizationId;
          this.store.dispatch(new FetchViewAllOrdersList(payload));
          this.viewAllOrderSubscription = this.viewAllOrdersList$.subscribe(
            (response) => {
              if (response && !response.error) {
                this.orderDetails = response;
                if(this.orderDetails && setToPastOrdersFromRoute && setToPastOrdersFromRoute == "past" ){
                  this.selectStatusCategory(3);
                }
                else if (this.orderDetails) this.selectStatusCategory(0);
              }
            }
          );
        } else {
          const inProgressOrderIds = 
          {
            orderIds: this.store.selectSnapshot(SelectedOrderState.getInprogressOrderIds),
            orgId: Config.organizationId,
          };
          if (inProgressOrderIds && inProgressOrderIds?.orderIds.length > 0)
            this.store.dispatch(
              new FetchInProgressOrderList(inProgressOrderIds)
            );
          this.inProgressOrderSubscription = this.inProgressOrderList$.subscribe(
            (response) => {
              if (response && !response.error) {
                this.inProgressOrders = response;
                if (this.inProgressOrders) this.selectStatusCategory(0);
              }
            }
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.viewAllOrderSubscription)
      this.viewAllOrderSubscription.unsubscribe();
    if (this.inProgressOrderSubscription)
      this.inProgressOrderSubscription.unsubscribe();
  }

  selectStatusCategory(item) {
    if (this.IsFromOrderSuccess) {
      if (item == 0) this.store.dispatch(new changeShowStatus(true));
      else {
        this.store.dispatch(new changeShowStatus(false));
        this.store.dispatch(new fromOrderSuccess(false));
      }
    } else this.store.dispatch(new changeShowStatus(false));

    if (this.orderDetails || this.inProgressOrders) {
      if (item === 1 || item == 0) {
        if (this.inProgressOrders)
          this.setCurrentFilteredOrders(this.inProgressOrders);
        if (this.orderDetails) this.setCurrentFilteredOrders(this.orderDetails);
      } else if (item === 2) {
        this.viewOrdersAll = [];
      } else {
        if (this.inProgressOrders)
          this.setPastFilteredOrders(this.inProgressOrders);
        if (this.orderDetails) this.setPastFilteredOrders(this.orderDetails);
      }
    }
    this.isActive = item;
  }

  setPastFilteredOrders(orders) {
    this.viewOrdersAll = orders.filter(
      (order) =>
        order.orderStatus.textCode === 'ORDERSTATUS04' ||
        order.orderStatus.textCode === 'ORDERSTATUS05'
    );
  }

  setCurrentFilteredOrders(orders) {
    this.viewOrdersAll = orders.filter(
      (order) =>
        order.orderStatus.textCode === 'ORDERSTATUS01' ||
        order.orderStatus.textCode === 'ORDERSTATUS02' ||
        order.orderStatus.textCode === 'ORDERSTATUS03'
    );
  }
  subscribeStatus() {
    this.getStatus$.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.showStatus = status;
    });
  }
  subscribeIsSuccessPage() {
    this.getIsSuccessPage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.IsFromOrderSuccess = status;
      });
  }
}
