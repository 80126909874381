import { Component } from '@angular/core';
import { OrderCompleteBaseComponent,DeliveryState,AddressState ,
  SweetAlertService,SharedService,VenueManagementService,LocationState,
  AnalyticsTrackingType,
  AuthState,
  SetLoginFrom,
  PurchaseAnalytics,
  setAnalyticsData,WhitelabelState} from '@moduurnv2/libs-orderingapp/src';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { Store, Select } from '@ngxs/store';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {ModalService} from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';

import { MessagingService } from '@moduurnv2/web/core/services/messaging-service';
import { TranslateService } from '@ngx-translate/core';
import { AddressWrapperComponent} from './../../shared/address-wrapper/address-wrapper.component';
import { takeUntil } from 'rxjs/operators';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { AnalyticsHandler } from '@moduurnv2/core/services';
import { LoginDesktopComponent } from '../../login/components/login-desktop/login-desktop.component';
import { Location } from '@angular/common';
import { PaymentMethodState } from '@moduurnv2/libs-orderingapp/src/core';
@Component({
  selector: 'moduurnv2-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent extends OrderCompleteBaseComponent {
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    appFontFamily: true,
  };
  
  constructor(
    fb: FormBuilder,
    store: Store,
    protected router: Router,
    sweetAlert: SweetAlertService,
    spinner: NgxSpinnerService,
    shared: SharedService,
    venueManagementService: VenueManagementService,
    modal: ModalService,
    messagingService: MessagingService,
    public analyticsHandler: AnalyticsHandler,
    protected translate: TranslateService,
    _location:Location
  ) {
    super(fb,store,router,sweetAlert,spinner,shared,venueManagementService,modal,messagingService,translate,_location)
  }

  isServiceFeeCapablePaymentMethod () {
    let newCard = this.store.selectSnapshot(
      PaymentMethodState.getNewCard
    );
    
    return newCard != null
  }

  confirmOrderAndContinue(){
    this.trackAnalyticsPurchase()
    const customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    const authDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    let header = 'Please login to complete this order';
    let isModalOpen = true;
    if(other.employeeLoyalty && other.employeeLoyalty.isAllow){
      if(!authDetails?.isLoggedIn){
       return this.modal.showSmallModal(AlertPopupComponent,{data:{
          header:header,
          actionable:authDetails?.isLoggedIn ? false : true ,
          closeBtnText:authDetails?.isLoggedIn ? 'Ok': this.translate.instant('cancel'),
          proceedText:'Login'
        },
        whenClosed: (data) => {
          if(isModalOpen){
            if(!authDetails?.isLoggedIn && data){
              this.store.dispatch(new SetLoginFrom(undefined));
              this.modal.showMediumModalDesktop(LoginDesktopComponent);
            } 
            // else if(authDetails?.isLoggedIn){
            //   this.router.navigate([`locations/${locationId}/menu`]);
            // }
          } 
          isModalOpen = false;
        }})
      }
    }
    if(this.validationBeforeConfirmOrder()){
      this.confirmOrder();
      let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
      this.analyticsHandler.setEvents("Transactions", "Ecommerce", "Transactions",  delivery.textCode, delivery);
    }
  }

  trackAnalyticsPurchase(){
    // console.log("purchase:", purchase)
    if(location){

      const purchaseAnalytics: PurchaseAnalytics = {
        _id: this.paymentState?.customerProfileId,
        amount: this.cartPrice?.price,
        analyticsTrackingType: AnalyticsTrackingType.PURCHASE,
        paymentType:this.selectedPaymentType,
        totalGross:this.cartPrice?.taxes,
        totalNet:this.cartPrice?.subTotal,
        tips:this.cartPrice?.tip,
      };
    this.store.dispatch(new setAnalyticsData(purchaseAnalytics));}
  }

  validationBeforeConfirmOrder(){
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(this.validateDeliveryLocation(delivery) && this.curbSidePreValidation(selectedLocation._id,delivery))
    return true;
    else
    return false;
  }

  validateDeliveryLocation(delivery) {
      if(delivery && (delivery.textCode =='DELIVERYTYPE01' || delivery.textCode =='DELIVERYTYPE04')){
        let selectedAddress = this.store.selectSnapshot(AddressState.getDeliveryAddress);
        if(selectedAddress)
          return true;
        else {
          this.forceUserSelectedDeliveryAddress();
          return false;
        }
      } else {
        return true;
      }
  }

  forceUserSelectedDeliveryAddress() {
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    if (this.isMobileView) {
      this.modal.showSmallModal(AddressWrapperComponent);
    } else {
      this.modal.showMediumModalDesktop(AddressWrapperComponent);
    }
  }

  curbSidePreValidation(locationId,selectedDelivery){
    if(selectedDelivery?.textCode == 'DELIVERYTYPE07' && !this.isMobileView){
      const authDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if(authDetails?.isLoggedIn){
        return true;
      } else {
        this.curbSidePickUpValidation(locationId,selectedDelivery.text);
      }
      return false;
    } else {
      return true;
    }
  }
  

  curbSidePickUpValidation(locationId,deliveryTypeText){
    const authDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let header;
    let isModalOpen = true;
    if(!authDetails?.isLoggedIn){
      header =  this.translate.currentLang == 'en' ? (this.translate.instant('curbside-desktop-warning-one') + deliveryTypeText + ' order. '+this.translate.instant('curbside-desktop-warning-two')) : (this.translate.instant('curbside-desktop-warning-one') + this.translate.instant('curbside-desktop-warning-two'));
    } else {
      header = this.translate.currentLang == 'en' ? `Please note that for ${deliveryTypeText} you must log in to your mobile app under the same user name to let the kitchen know that you have arrived.` : this.translate.instant('curbside-desktop-warning-loggedin')
    }
    this.modal.showSmallModal(AlertPopupComponent,{data:{
        header:header,
        actionable:authDetails?.isLoggedIn ? false : true ,
        closeBtnText:authDetails?.isLoggedIn ? 'Ok': this.translate.instant('cancel'),
        proceedText:'Login'
      },
      whenClosed: (data) => {
        if(isModalOpen){
          if(!authDetails?.isLoggedIn && data){
            this.store.dispatch(new SetLoginFrom(undefined));
            this.modal.showMediumModalDesktop(LoginDesktopComponent);
          } 
          // else if(authDetails?.isLoggedIn){
          //   this.router.navigate([`locations/${locationId}/menu`]);
          // }
        } 
        isModalOpen = false;
      }})
  }
  
  // addressSelectionModalToShow(deliveryType) {
  //   if(deliveryType && (deliveryType =='DELIVERYTYPE01' || deliveryType =='DELIVERYTYPE04')){
  //         return AddressListDesktopComponent;
  //   }
  // }
}
