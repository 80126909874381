const baseurl = 'https://b1wso2.moduurn.ca/';
const baseApiV2 = 'https://api-v2.moduurn.com/';
const imagebaseurl = 'https://images.moduurn.com/';

import { common, apigenerator } from './environment.common';

const urls = apigenerator(baseurl, baseApiV2);

export const environment = {
  production: true,
  ...urls,
  ...common,
  imagebaseurl: imagebaseurl,
  federatedApi: baseurl + 'federatedapi/v1/',
  wso2URL: 'https://sso.moduurn.ca',
  azureSSO: {
    // clientId: '35hg3QmJ1h8sLJfDhWQRDS5fHwAa',
    clientId: 'a223JamNMD47cuXgitQjcZTepfUa',
    // secret: 'NPz8vKDmJumsBlXpfbvdiT_Ovfga'
    secret: 'K3Ly3xf6Nf5g6TWPY03ieNSZaCoa',
  },
};
