import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ViewAllOrders } from '../models';
import {
  FetchViewAllOrdersList,
  FetchInProgressOrderList,
  UpdateCurbsideNotification,
  FetchInProgressCurbside,
} from '../actions';
import { Injectable } from '@angular/core';
import { OrderDetailsService, ViewAllOrdersService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AuthState } from './auth.state';
import { SelectedOrderState } from './selected-order.state';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { throwError } from 'rxjs';

export class ViewAllOrdersStateModel {
  viewAllOrdersList: ViewAllOrders[];
  inProgressOrderList: ViewAllOrders[];
  curbsideInProgress: number;
}

@State<ViewAllOrdersStateModel>({
  name: 'viewallorders',
  defaults: {
    viewAllOrdersList: undefined,
    inProgressOrderList: undefined,
    curbsideInProgress: 0,
  },
})
@Injectable()
export class ViewAllOrdersState {
  constructor(
    private viewAllOrders: ViewAllOrdersService,
    private orderProgress: OrderDetailsService,
    private store: Store
  ) {}

  @Selector()
  static getViewAllOrdersList(state: ViewAllOrdersStateModel) {
    return state.viewAllOrdersList;
  }

  @Selector()
  static getInProgressOrderList(state: ViewAllOrdersStateModel) {
    return state.inProgressOrderList;
  }

  @Selector()
  static getCurbsideInProgress(state: ViewAllOrdersStateModel) {
    return state.curbsideInProgress;
  }

  @Action(FetchViewAllOrdersList)
  fetchViewAllOrders(
    { setState }: StateContext<ViewAllOrdersStateModel>,
    { payload }: any
  ) {
    let custId = payload.custId; //;// = '5e706d159c7d555586050ba4';
    let orgId = payload.orgId; // = '5a5480ee2c393854d50189e4';
    if (custId) {
      return this.viewAllOrders.fetchViewAllOrders(custId, orgId).pipe(
        tap((response) => {
          if (response) {
            setState(
              patch({
                viewAllOrdersList: response['orders'],
              })
            );
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  @Action(FetchInProgressOrderList)
  fetchInProgressOrderList(
    { setState }: StateContext<ViewAllOrdersStateModel>,
    { payload }: any
  ) {
    let body = {
      customerId: payload && payload.custId ? payload.custId : null,
      orderIds: payload && payload.orderIds ? payload.orderIds : null,
      organizationId: payload && payload.orgId ? payload.orgId :null,
    };
    return this.viewAllOrders.fetchInprogressOrders(body).pipe(
      tap((response) => {
        if (response && response['orders']) {
          setState(
            patch({
              inProgressOrderList: response['orders'],
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(UpdateCurbsideNotification)
  updateCurbsideNotification(
    { setState }: StateContext<ViewAllOrdersStateModel>,
    { payload }: any
  ) {
    return this.orderProgress.updateNotification(payload).pipe( tap(
      (response) => {
        const result = response as any;
        if (result.error) {
          return console.error(result.error);
        } else {
          let payload = {};
          const loggedInUser = this.store.selectSnapshot(
            AuthState.getCommonAuthDetails
          );

          if (loggedInUser?.customerId) {
            payload['custId'] = loggedInUser.customerId;
            payload['orgId'] = Config.organizationId;
            this.store.dispatch(new FetchViewAllOrdersList(payload));
          } else {
            const inProgressOrderIds = this.store.selectSnapshot(
              SelectedOrderState.getInprogressOrderIds
            );
            if (inProgressOrderIds && inProgressOrderIds.length > 0)
              this.store.dispatch(
                new FetchInProgressOrderList(inProgressOrderIds)
              );
          }
        }
      },
      (err) => {
        console.log(err);
      }
    ));
  }

  @Action(FetchInProgressCurbside)
  fetchInProgressCurbside({ setState }: StateContext<ViewAllOrdersStateModel>) {
    let body = {
      customerId: null,
      orderIds: null,
      organizationId: Config.organizationId,
    };
    const loggedInUser = this.store.selectSnapshot(
      AuthState.getCommonAuthDetails
    );
    if (loggedInUser?.customerId) {
      body.customerId = loggedInUser.customerId;
    } else {
      const inProgressOrderIds = this.store.selectSnapshot(
        SelectedOrderState.getInprogressOrderIds
      );
      body.orderIds = inProgressOrderIds;
    }
    return this.viewAllOrders.fetchInprogressCurbside(body).pipe(
      tap((response) => {
        if (response && response['pendingCurbsideOrders'] != undefined) {
          setState(
            patch({
              curbsideInProgress: response['pendingCurbsideOrders'],
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
