<form [formGroup]="userDetailsForm" (ngSubmit)="confirmOrderAndContinue()">
  <div class="user-info-section">
    <div class="user-info-control">
      <label class="user-label" aria-label="first name"
        >{{ 'first-last-name' | translate }} <span class="alert">*</span></label
      >
      <input
        type="text"
        class="user-input"
        data-qa="user-name"
        formControlName="name"
        placeholder="John Doe"
        data-qa="name"
        aria-label="first name"
      />
      <div
        *ngIf="userDetailsForm.controls.name.errors && formSubmitted"
        class="alert-text"
        aria-label="name-error"
      >
        {{ 'name-alert' | translate }}
      </div>
    </div>
    <div class="user-info-control">
      <label class="user-label" aria-label="mobile number"
        >{{ 'mobile-number' | translate }} <span class="alert">*</span></label
      >
      <phone-input
        type="tel"
        id="phoneNumber"
        name="phoneNumber"
        class="phoneNumber"
        placeholder="{{ 'enter-mobile' | translate }}"
        [phone]="userDetails.phone"
        data-qa="contact-number"
        aria-label="contact number"
        [submitted]="formSubmitted"
        (Formatted)="formattedPhone($event)"
      ></phone-input>
      <div *ngIf="showDropdown" class="dropdown-content">
        <div class="drop-value" (click)="onSelect($event)">
          <img [src]="canadaFlag" alt="flag"/>
        </div>
      </div>
      <div
        *ngIf="
          !this.phoneNumber.valid &&
          this.phoneNumber.international == '' &&
          formSubmitted
        "
        class="alert-text"
      >
        {{ 'contact-number-error' | translate }}
      </div>
    </div>
    <!-- <div class="options">
      <label class="options-label" aria-label="terms and conditions"
        >{{ 'i-agree' | translate }}
        <span class="terms-conditions">{{
          'terms-conditions' | translate
        }}</span>
        <input
          type="checkbox"
          data-qa="agree-to-terms"
          aria-label="agree to terms and conditions"
          formControlName="acceptTerms"
        />
        <span class="checkmark"></span>
      </label>
      <div
        *ngIf="userDetailsForm.controls.acceptTerms.errors && formSubmitted"
        class="alert-text"
      >
        {{ 'accept-terms' | translate }}
      </div>
    </div> -->
    <button
      *ngIf="isMobileView"
      type="submit"
      class="complete-btn"
      data-qa="complete-order"
      aria-label="complete order"
      [disabled]="disableButton"
    >
      {{ 'complete-order' | translate }}
    </button>
  </div>
  <div *ngIf="!isMobileView" class="complete-button-container">
    <ng-container *ngIf="(!zeroPriceOrder); else zeroPaymentOrder">
    <button
      type="submit"
      class="complete-btn-desktop"
      data-qa="complete-order"
      aria-label="complete order"
    >
      {{ 'Pay' | translate }}
      <span class="amount">{{
        (cartPrice.totalAmount + (isServiceFeeCapablePaymentMethod() && cartPrice?.serviceFee?.total ? cartPrice?.serviceFee?.total : 0)) | formattedCurrency
      }}</span>
    </button>
    </ng-container>
    <ng-template #zeroPaymentOrder>
      <button
        class="complete-btn-desktop place-zero-payorder"
        type="submit"
        data-qa="place-order-button"
        attr.aria-label="{{ 'PlaceOrder' | translate }}"
        aria-label="place order"
        [theme]="themeOptions"
      >
        {{ 'PlaceOrder' | translate }}
      </button>
    </ng-template>
  </div>
</form>
