import {
  BaseComponent,
  CartState,
  Delivery,
  DeliveryState,
  environment,
  FetchETA,
  PerformReordering,
  SharedService,
  WhitelabelState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectedOrderState } from '@moduurnv2/libs-orderingapp/src/core';

import { OrderDetailsService } from './../../../core/services/order-details.service';
import { OrderReceiptService } from './../../../core/services/order-receipt.service';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
// import * as FileSaver from "file-saver";

interface Constant {
  text: string;
  textCode: string;
  textType: string;
}
export interface OrderReceipt {
  _id: string;
  orderNumber: string;
  location: {
    name: string;
    address: string;
    hidePrice: boolean;
  };
  deliveryMethod: Constant;
  delivery: {
    address: string;
  };
  paymentMode: string;
  deliveryStatus: Constant;
  itemDetails: any;
  orderPlacedDate: string;
  locationDetails: any;
  orderLog: any;
  orderedBy: any;
  payment: any;
  totalItemCount: string;
  totalPrice: string;
}
export class OrderReceiptBaseComponent extends BaseComponent {
  orderSettings:any;
  hidePrice:boolean;
  heading: string;
  orderReceipt: OrderReceipt;
  deliveryStatusText: string;
  private _serviceOrgSubscription: Subscription;
  private _orderReceiptSubscription: Subscription;
  private _receiptDownloadSubscription: Subscription;
  showEstimatedReadyTime:boolean = false;
  minPrepTime : Number;
  maxPrepTime: Number;
  other : any;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<Delivery>;
  selectedDelivery: Delivery;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _orderReceiptService: OrderReceiptService,
    private _orderDetailsService: OrderDetailsService,
    private _location: Location,
    private store: Store,
    public modal:ModalService,
    private shared: SharedService,
    private translate: TranslateService
  ) {
    super();
    this.heading = this.translate.instant('your-order');
  }

  imgBasePath: string = environment.image_basepath;
  icon = {
    cancel: this.imgBasePath + environment.images.cancel_icon,
    cancelPng: this.imgBasePath + environment.images.cancel_icon_png,
    save: this.imgBasePath + environment.images.save_icon,
    savePng: this.imgBasePath + environment.images.save_icon_png,
    orderAgain: this.imgBasePath + environment.images.order_again_icon,
    orderAgainPng: this.imgBasePath + environment.images.order_again_icon_png,
  };
  orderId: string;
  isMobileDevice: boolean = false;
  serviceOrg: object;

  ngOnInit(): void {
    this.selectedDelivery$
    .pipe(takeUntil(this.destroy$))
    .subscribe((delivery) => {
      if (delivery) {
        this.selectedDelivery = delivery;
      }
    })

     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileDevice = isMobileView)
    );
    const order_id = this.store.selectSnapshot(
      SelectedOrderState.getSelectedOrderId
    );
    if (order_id) {
      this.orderId = order_id;
      this.getOrderReceiptDetails(this.orderId);
      this.getServiceOrg();
    }

    this.store.dispatch(new FetchETA());

    this.other = this.store.selectSnapshot(WhitelabelState.getOther);

  }

  ngOnDestroy() {
    if (this._orderReceiptSubscription)
      this._orderReceiptSubscription.unsubscribe();
    if (this._receiptDownloadSubscription)
      this._receiptDownloadSubscription.unsubscribe();
    if (this._serviceOrgSubscription)
      this._serviceOrgSubscription.unsubscribe();
  }

  getOrderReceiptDetails(orderId) {
    this._orderReceiptSubscription = this._orderReceiptService
      .getOrderReceiptDetails(orderId)
      .subscribe((res) => {
        if (res && res.data) {
          this.orderReceipt = res.data;
          if(this.orderReceipt && this.orderReceipt.location){
            this.hidePrice = this.orderReceipt.location.hidePrice
          }
          if(this.orderReceipt && this.orderReceipt.locationDetails){
            this.orderSettings = this.orderReceipt.locationDetails.orderSettings;
          }
          this.deliveryStatusText = this._orderDetailsService.getOrderStatusText(
            this.orderReceipt.deliveryStatus.textCode,
            this.orderReceipt.deliveryMethod.textCode
          );

          this.showEstimatedReadyTime = this.checkEstimatedReadyTimeExists(this.orderReceipt,this.orderSettings);
        }
      });
  }

  getServiceOrg() {
    this._serviceOrgSubscription = this._orderReceiptService
      .fetchServiceOrg()
      .subscribe((res) => {
        if (res && res.physicalAddress) {
          this.serviceOrg = res;
        }
      })
  }

  saveReceipt() {
    const dataForReceipt = {
      offset: new Date().getTimezoneOffset(),
      order: {
        _id: this.orderReceipt._id,
        deliveryMethod: this.orderReceipt.deliveryMethod,
        locationId: this.orderReceipt.locationDetails,
        orderLog: this.orderReceipt.orderLog,
        orderNumber: this.orderReceipt.orderNumber,
        orderedBy: this.orderReceipt.orderedBy,
        payment: this.orderReceipt.payment,
        totalItemCount: this.orderReceipt.totalItemCount,
        totalPrice: this.orderReceipt.totalPrice,
        serviceFee: (this.orderReceipt?.locationDetails?.serviceFee) ? this.orderReceipt.locationDetails.serviceFee : {}
      },
      time: new Date().toISOString(),
    };
    this._receiptDownloadSubscription = this._orderReceiptService
      .fetchReciept(dataForReceipt)
      .subscribe(
        (data) => {
          let pdfData = data as string;
          const downloadLink = document.createElement('a');
          const fileName = `${dataForReceipt.order.orderNumber}.pdf`;
          downloadLink.href = pdfData;
          downloadLink.download = fileName;
          downloadLink.click();
          var blob = new Blob([pdfData], {type: "application/pdf"});
          // FileSaver.saveAs(blob,fileName);
        },
        (err) => {}
      );
  }
  goBack() {
    if (
      this.orderReceipt &&
      this.orderReceipt.locationDetails &&
      this.orderReceipt.locationDetails._id
    ) {
      this.router.navigate([
        `/locations/${this.orderReceipt.locationDetails._id}/order-details`,
      ]);
    }
  }

  checkEstimatedReadyTimeExists(receiptDetails,orderSettings){
    let show = false;
    if(receiptDetails?.deliveryStatus.text !== 'Future'){
      const locationMinPrepTime = orderSettings?.minPrepTime ? orderSettings?.minPrepTime : undefined;
      const locationMaxPrepTime = orderSettings?.maxPrepTime ? orderSettings?.maxPrepTime : undefined;
      this.minPrepTime = (receiptDetails.minPrepTime && receiptDetails.minPrepTime>0) ? receiptDetails.minPrepTime : locationMinPrepTime;
      this.maxPrepTime = (receiptDetails.maxPrepTime && receiptDetails.maxPrepTime>0) ? receiptDetails.maxPrepTime : locationMaxPrepTime;
      if((this.minPrepTime || this.maxPrepTime) || (receiptDetails?.orderLog?.readyEstTime)){
        show = true;
      }

      if((receiptDetails.deliveryStatus.textCode == 'ORDERSTATUS01' || receiptDetails.deliveryStatus.textCode == 'ORDERSTATUS02') && receiptDetails.deliveryMethod.textCode == 'DELIVERYTYPE04'){
        show = false;
      }
    }
    return show;

  }

  formatDate(date){

    if(date){
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
       return  moment(date).format('MMM DD ' + timeFormat);
    }
    else{
      return '';
    }

  }

  get getMinPrep() {
    const eta = this.store.selectSnapshot(CartState.getETA);
    if(eta?.minPrepTime) {
      return eta?.minPrepTime;
    } else {
      return this.minPrepTime;
    }
  }

  get getMaxPrep() {
    const eta = this.store.selectSnapshot(CartState.getETA);
    if(eta?.maxPrepTime) {
      return eta?.maxPrepTime;
    } else {
      return this.maxPrepTime;
    }
  }

  reorder(order) {
    let cart = this.store.selectSnapshot(CartState.getCart);
    if(cart?.cart_id && (cart?.comboPrice?.length || cart?.itemPrice?.length)){
    this.modal.showSmallModal(AlertPopupComponent, {
      data: {
        header: this.translate.instant('reorder-confirmation'),
        actionable: true,
        closeBtnText:this.translate.instant('cancel'),
        proceedText:this.translate.instant('reorder')
      },
      whenClosed: (data) => {
        if (data) {
          this.performReorder(order);
        }
      },
    });
    } else {
      this.performReorder(order);
    }
  }

  performReorder(order){
    this.store.dispatch(new PerformReordering(order)).subscribe(response => {
      const { _id: locationId } = this.store.selectSnapshot(
             (app) => app.location.selectedLocation
           );
      this.router.navigate([`/locations/${locationId}/order`]);
     }, err => {
     });
  }
}
