import {
  BaseComponent,
  environment,
  LocationDefaults,
  FetchLocationDefaults,
  LocationDefaultState,
  FetchPaymentMethods,
  PaymentMethodState,
  SweetAlertService,
  LocationState,
  LocationStatus,
  DeliveryState,
  Delivery,
  SetSpecialInstruction,
  UpdateCartTotal,
  FetchCart,
  ClearSelectedCard,
  SetPaymentMethod,
  UpdateCart,
  GeoLocationState,
  FetchCurrentCoordinates,
  ChangePopupStatus,
  ValidateDeal,
  DealsState,
  Deals,
  RemoveDeal,
  SelectDeal,
  AuthState,
  WhitelabelState,
  UpdateCartDetails,
  CartResponse,
  OrderLevelModifierState,
  SetPaymentSelected,
  FetchOrderLevelModifiers,
  SetMealPaymentDetails,
  FetchLocationStatus,
  ClearPaymentDetails
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, ViewChild, ElementRef, Input, Renderer2 } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  Cart,
  CartState,
  CartItems,
  VenueManagementState,
  VenueManagementService,
  SetAckrooPaymentDetails,
  PaymentModes,
  ClearDeals,
  AddressState,
  QRScanDetails
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  paymentCardType,
  paymentMethods,
} from './../../../core/services/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { PickupLocationUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/pickup-location-update-selection/pickup-location-update-selection.component';
import { RoomUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-update-selection/room-update-selection.component';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import transformScale from '@turf/transform-scale';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { AddressListComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book/address-list.component';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;
export class ReviewPayBaseComponent extends BaseComponent implements OnInit {
  @ViewChild('specialInstruction') specialInstructionField: ElementRef;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  desktopView: boolean = false;
  @Select(LocationState.getLocationStatus) locationStatus$: Observable<
    LocationStatus
  >;
  addCardMethod: boolean = false;
  nextPage: boolean = false;
  @Select(CartState.getCart) cartPrice$: Observable<Cart>;
  @Select(CartState.getCartTip) tip$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(PaymentMethodState.getSelectedPayment)
  selectedPayment$: Observable<string>;
  @Select(PaymentMethodState.getAckrooPaymentDetails) ackrooPaymentDetails$;
  @Select(PaymentMethodState.getMealPaymentDetails) mealPaymentDetails$;
  @Select(PaymentMethodState.getSelectedCards) selectedCard$;
  @Select(PaymentMethodState.getNewCard) getNewCard$;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  @Select(CartState.getSpecialInstruction) specialInstruction$: Observable<
    string
  >;
  @Select(GeoLocationState.getCurrentCoordinates)
  getCurrentCoordinates$: Observable<any>;
  @Select(DealsState.getSelectedDeal) selectedDeal$: Observable<Deals>;
  @Select(DealsState.getQueueDeal) queuedDeal$: Observable<Deals>;
  @Select(CartState.getCartResponse) cartResponse$: Observable<CartResponse>;
  @Select(OrderLevelModifierState.getModifiers) getModifiers$: Observable<any>;
  isSpecialInstruction: boolean = false
  isSpecialInstructionPresent: boolean = false;
  locationDefaults: LocationDefaults;
  cartPrice: Cart;
  cartItems: CartItems[];
  specialInstructionText: String = '';
  locationStatus: LocationStatus;
  delivery: Delivery;
  isMobileView: boolean = true;
  imgBasePath: string = environment.image_basepath;
  icon = {
    close: this.imgBasePath + environment.images.close,
    // special_instructions:
    //   this.imgBasePath + environment.images.special_instructions,
    special_instructions: 'assets/images/special-instructions-new.svg',
    // alert: this.imgBasePath + environment.images.alert,
    alert: 'assets/images/alert-icon-new.svg',
    info: 'assets/images/Info.svg',
    credit_card: this.imgBasePath + environment.images.visa_card,
    pay_on_delivery: this.imgBasePath + environment.images.credit_card,
    selected: this.imgBasePath + environment.images.success,
  };
  specialInstructions: string;
  hideInstructions: boolean;
  specialInstructionPopupHeading: string;
  specialInstructionPopupMessage: string;
  selectedPayment: string;
  payWithIcon: string;
  tip: any;
  selectedPaymentType: string;
  isPayOnDelivery: boolean = false;
  giftCard: boolean = false;
  selectedDelivery: any;
  virtual: boolean;
  locCloseMsg: string;
  grandTotalAmount: number;
  orderSettings: any;
  allowNoPaymentOrdering: boolean = false;
  zeroPriceOrder: boolean = false;
  ackrooPaymentDetails: any = undefined;
  mealPaymentDetails: any = undefined;
  selectedCard: any = undefined;
  newCard:any = undefined;
  selectedDeal: Deals;
  queuedDeal: Deals;
  enableCoupon : boolean;
  couponAppliedInCart:boolean;
  enableExpressDeliveryOption:boolean = false;
  showExpressDeliveryOption:boolean = false;
  isInitialLoad:boolean = true;
  isMobile: boolean;
  activePaymentMethods=[];
  paymentBtnText:string = this.translate.instant('Pay');
  qrScanDetails:QRScanDetails;
  selectedLocation;
  hideSpecialInstructions:boolean=undefined;
  clickedOnSpecialInstruction:boolean= false;  
  isEmployeeLoyaltyOrder : boolean = false;

  constructor(
    public store: Store,
    public router: Router,
    public sweetAlert: SweetAlertService,
    public spinner: NgxSpinnerService,
    public shared: SharedService,
    public translate: TranslateService,
    public venueManagementService: VenueManagementService,
    public modal: ModalService,
    public _location: Location,
    public renderer : Renderer2,
  ) {
    super();
    this.qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    this.selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationDefaults) => {
        if (locationDefaults) {           
          this.locationDefaults = locationDefaults;
          this.orderSettings = this.locationDefaults.locationData.orderSettings;
          let paymentMethods = this.shared.getActivePaymentMethods(this.orderSettings.paymentModes);  
          if(paymentMethods){
            this.activePaymentMethods = paymentMethods.filter(method => method['active']);
            if(this.checkOnlyCODExists()){
              this.paymentBtnText = this.activePaymentMethods[0].text;
            }
          }
        }
      });
    this.locationStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationStatus) => {
        if (locationStatus) {
          this.locationStatus = locationStatus;
        }
      });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });
    this.ackrooPaymentDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ackrooPaymentDetails) => {
        if (ackrooPaymentDetails) {
          this.ackrooPaymentDetails = ackrooPaymentDetails;
        } else this.ackrooPaymentDetails = undefined;
      });

      this.mealPaymentDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mealPaymentDetails) => {
        if (mealPaymentDetails) {
          this.mealPaymentDetails = mealPaymentDetails;
        } else this.mealPaymentDetails = undefined;
      });

    this.selectedCard$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedCard) => {
        if (selectedCard) {
          this.selectedCard = selectedCard;
        } else this.selectedCard = undefined;
      });
    this.getNewCard$.pipe(takeUntil(this.destroy$))
    .subscribe((newCard) => {
      if (newCard) {
        this.newCard = newCard;
      } else this.newCard = undefined;
    });
    this.cartPrice$.pipe(takeUntil(this.destroy$)).subscribe((cartPrice) => {
      if (cartPrice) {
        this.cartPrice = cartPrice;
        this.cartPrice.totalAmount = this.cartPrice.price;
        if(this.isInitialLoad){
        if(this.cartPrice.deliveryCharge.expressDeliveryCharge && this.cartPrice.deliveryCharge.expressDeliveryCharge > 0)
        this.enableExpressDeliveryOption = true;
        }
        this.isInitialLoad = false;
        if(this.cartPrice.totalAmount == 0){
          this.nextPage = true;
          this.zeroPriceOrder = true;
        }
        else{
          // this.nextPage = false;
          this.zeroPriceOrder = false;
        }
        // this.couponAppliedInCart = this.cartPrice.couponDiscount > 0 ? true: false;
        // if(!this.couponAppliedInCart){
        //   this.store.dispatch(new ClearDeals());
        // }
      }
    });

    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      if (cartItems) {
        this.cartItems = cartItems;
        if (this.cartItems[0] && (this.cartItems[0].menuItems?.length == 0 && this.cartItems[0].combos?.length == 0)) {
          const selectedLocation = this.store.selectSnapshot(
            LocationState.getSelectedLocation
          );
          this.router.navigate([`/locations/${selectedLocation._id}/menu`]);
        }
        if (this.cartItems[0].isDeliverable === false) {
          this.showUndeliveredMessage();
        }
      }
    });
    this.specialInstruction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((specialInstruction) => {
        if (specialInstruction) {
          this.specialInstructionText = specialInstruction;
          this.isSpecialInstructionPresent = true;
        }
      });
    this.tip$.pipe(takeUntil(this.destroy$)).subscribe((tip) => {
      if (tip) {
        this.tip = tip;
      }
    });
    this.selectedDeal$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedDeal) => {
        if (selectedDeal) {
          this.selectedDeal = selectedDeal;
        } else {
          this.selectedDeal = null;
        }
      });
    this.queuedDeal$.pipe(takeUntil(this.destroy$)).subscribe((queuedDeal) => {
      if (queuedDeal) {
        this.queuedDeal = queuedDeal;
      } else {
        this.queuedDeal = null;
      }
    });
    this.selectedPayment$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedPayment) => {
        if (selectedPayment) {
          this.selectedPayment = selectedPayment;
        } else this.selectedPayment = undefined;
      });
    this.selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    
    this.cartResponse$
    .pipe(takeUntil(this.destroy$))
    .subscribe((cartResponse) => {
      if (cartResponse && cartResponse.status == 1) {
        this.store.dispatch(new FetchCart());
      }
    });
    this.checkNoPaymentOrdering();
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    if(!this.isMobileView){
      this.isSpecialInstruction=true;
      this.renderer.listen('window','click',(e:Event)=>{
        if(this.clickedOnSpecialInstruction && e?.target !== this.specialInstructionField?.nativeElement){        
          this.saveSpecialInstruction(this.specialInstructionField.nativeElement.value)
          this.clickedOnSpecialInstruction= false;   
          // if(this.specialInstructionField.nativeElement.value.trim().length > 0){          
          //   this.closeSpecialInstructionView()
          // }   
        }
      })
    }
      
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    if(other.employeeLoyalty && other.employeeLoyalty.isAllow){
      this.isEmployeeLoyaltyOrder = true;
    }
    else{
      this.isEmployeeLoyaltyOrder = false;
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchCart());
    this.store.dispatch(new FetchLocationDefaults());
    this.store.dispatch(new FetchPaymentMethods());
    this.fetchOrderLevelModifiers();
    // this.dealActions();
    this.selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    if (
      this.locationDefaults &&
      this.locationDefaults.locationData &&
      this.locationDefaults.locationData.orderSettings
    ) {
     
      this.specialInstructions = this.locationDefaults.locationData.orderSettings.specialInstruction;
      this.hideInstructions = this.locationDefaults.locationData.orderSettings.hideInstructions;
      this.specialInstructionPopupHeading = this.locationDefaults.locationData.orderSettings.specialInstructionPopupHeading;
      this.specialInstructionPopupMessage = this.locationDefaults.locationData.orderSettings.specialInstructionPopupMessage;      
      this.hideSpecialInstructions= this.locationDefaults.locationData.orderSettings.hideSpecialInstructions?
      this.locationDefaults.locationData.orderSettings.hideSpecialInstructions:false;       
    }
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    let organizationOtherConfigs = this.store.selectSnapshot(
      WhitelabelState.getOther
    );
    this.enableCoupon = organizationOtherConfigs.enableCoupons;
    const organizationWhitelabelConfigs = this.store.selectSnapshot(
      WhitelabelState.getWhitelabel
    );
    if(organizationWhitelabelConfigs?.deliveryMethods?.length>0){
      let postalDeliveryIndex = organizationWhitelabelConfigs.deliveryMethods.findIndex(method=> method.deliveryMethod.textCode =='DELIVERYTYPE04');
      if(this.selectedDelivery?.textCode == 'DELIVERYTYPE04' && postalDeliveryIndex >-1 && organizationWhitelabelConfigs.deliveryMethods[postalDeliveryIndex].expressDeliveryCharge && organizationWhitelabelConfigs.deliveryMethods[postalDeliveryIndex].expressDeliveryCharge >0)
      this.showExpressDeliveryOption=true;
      else
      this.showExpressDeliveryOption=false;
    }
  }

  dealActions() {
    if (this.cartItems && !this.selectedDeal && this.queuedDeal) {
      this.store.dispatch(new SelectDeal(this.queuedDeal));
    }
    const auth = this.store.selectSnapshot(AuthState);
    if (auth?.loggedInUser?.isLoggedIn && (this.selectedDeal || this.queuedDeal)) {
      this.store.dispatch(
        new ValidateDeal(this.cartPrice.cart_id, this.cartPrice.subTotal)
      );
    }
  }

  openPaymentView() {
    this.store.dispatch(new SetPaymentSelected(true));
    if(!this.checkOrderLevelModifiers()){
      return   this.sweetAlert.toast('error', 'Sorry, Please select the required items to continue.');
    }

    this.router.navigate([`/payment`]);
  }
  openDeals() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/deals`]);
    }
  }
  closeReview() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/menu`]);
    }
  }
  openSpecialInstruction() {
    this.isSpecialInstruction = true;
    setTimeout(() => {
      this.specialInstructionField.nativeElement.focus();
    }, 0);
  }
  setDesktop(val) {
    if (!val.isdesktop) {
      this.desktopView = !val.isdesktop;
    }
    this.giftCard = false;
    if (val.type === 5) {
      if (val.savedCard) this.nextPage = true;
      this.isPayOnDelivery = !val.isdesktop;
    } else {
      this.isPayOnDelivery = false;
    }
    if (val.type === 4) {
      this.nextPage = false;
      this.isPayOnDelivery = false;
      this.giftCard = true;
    }
  }
  addCardDesktop(val) {
    if (val) {
      this.addCardMethod = true;
      this.nextPage = true;
    }
  }
  changePayMethod(val) {
    if (val) {
      this.nextPage = false;
      this.desktopView = false;
      this.store.dispatch(new SetAckrooPaymentDetails(undefined));
      this.store.dispatch(new SetMealPaymentDetails(undefined));
      this.store.dispatch(new ClearPaymentDetails());
    }
  }

  closeSpecialInstructionView() {
    this.isSpecialInstruction = false;
  }
  clickedOnSpecialInst(){    
    this.clickedOnSpecialInstruction=true;
  }
  deleteSpecialInstruction() {
    let text = '';
    this.saveSpecialInstruction(text);
  }
  saveSpecialInstruction(specialInstructionText) {
    if(specialInstructionText?.trim().length > 0){
      this.isSpecialInstruction = false;
    }
    this.updateSpecialInstruction(specialInstructionText);
    this.store.dispatch(new SetSpecialInstruction(specialInstructionText));
  }
  updateSpecialInstruction(specialInstructionText) {
    this.specialInstructionText = specialInstructionText;
    if (specialInstructionText.length > 0)
      this.isSpecialInstructionPresent = true;
    else this.isSpecialInstructionPresent = false;
  }

  updateGrandTotal(tip) {
    let totalAmount = this.cartPrice.subTotal;
    if(this.cartPrice.couponDiscount && this.cartPrice.couponDiscount>0){
      totalAmount = this.cartPrice.subTotal - this.cartPrice.couponDiscount;
    }
    let totalAmountWithoutTip = 0;
    if (this.cartPrice.tip)
      totalAmountWithoutTip = this.cartPrice.totalAmount - this.cartPrice.tip;
    else totalAmountWithoutTip = this.cartPrice.totalAmount;

    if (tip.type === '%') {
      let calculatedTip = (totalAmount * tip.value) / 100;
      this.cartPrice.tip = calculatedTip;
    } else if (tip.type === '$') {
      this.cartPrice.tip = parseFloat(tip.value);
    }
    if (this.cartPrice.tip) {
      this.cartPrice.totalAmount = totalAmountWithoutTip + this.cartPrice.tip;
    } else {
      this.cartPrice.totalAmount = totalAmountWithoutTip;
    }
    this.store.dispatch(new UpdateCartTotal(this.cartPrice));
  }

  getPaymentMethod() {
    let paymentState = this.store.selectSnapshot(
      PaymentMethodState.getPaymentState
    );

    switch (this.selectedPayment) {
      case 'Pay at Location':
        return (this.payWithIcon = 'assets/images/Pay-on-Delivery-Icon.svg');
        break;
      case 'Credit Card':
        {
          if (paymentState.cardType && paymentCardType[paymentState.cardType]) {
            return (this.payWithIcon =
              paymentCardType[paymentState.cardType].icon);
          } else {
            return (this.payWithIcon = 'assets/images/Credit-Card-Logo.svg');
          }
        }
        break;
      default:
        return (this.payWithIcon = 'assets/images/Pay-on-Delivery-Icon.svg');
    }
  }

  async placeOrder() {
    const geoFencingValidation = await this.validateLocationGeoFencing();
    if (this.validateVenueSelection() && geoFencingValidation) {
      this.virtual = this.delivery.textCode == 'DELIVERYTYPE04' ? true : false;
      if (this.delivery.textCode == 'DELIVERYTYPE05') {
        this.locCloseMsg = 'You can place the order once the event starts';
      } else {
        if (this.delivery.textCode == 'DELIVERYTYPE02') {
          this.locCloseMsg = 'Ordering is closed';
        } else {
          this.locCloseMsg =
            this.translate.instant('loc-close-error') ;
        }
      }

      if ((this.allowNoPaymentOrdering || this.zeroPriceOrder || this.checkOnlyCODExists()) && !this.isEmployeeLoyaltyOrder) {
        this.setCODOrder();
      }
      if(this.zeroPriceOrder && this.isEmployeeLoyaltyOrder){
        this.setLoyaltyOrder();
      }

      if (
        (this.locationStatus &&
          this.locationStatus.isOpenToday &&
          this.locationStatus.isCurrentlyOpen)
      ) {
        if((this.checkOnlyCODExists() && this.isMobileView) || !this.isMobileView || this.allowNoPaymentOrdering || (this.isEmployeeLoyaltyOrder && this.zeroPriceOrder)){
          this.router.navigate([`/order/complete`]);
        } else {
          this.openPaymentView();
        }
        // this.router.navigate([`/order/complete`]);
      } else if (
        this.locationStatus &&
        (!this.locationStatus.isOpenToday ||
          !this.locationStatus.isCurrentlyOpen) &&
        this.virtual == false
      ) {
        this.sweetAlert.toast('error', this.locCloseMsg);
      }
    }
  }

  showUndeliveredMessage() {
    if (this.router.url.split('/').pop() === 'order') {
      this.sweetAlert.alertError(
        '',
        'Some of the items in the cart are undeliverable to the selected address.'
      );
    }
  }

  checkNoPaymentOrdering() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }

  setCODOrder() {
    let paymentMethod = paymentMethods.find((method) => method.id == 5);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
  }
  setLoyaltyOrder() {
    let paymentMethod = paymentMethods.find((method) => method.id == 7);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
  }
  

  validateVenueSelection() {
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if (this.venueManagementService.isVenueManagementOrder(delivery.textCode) && this.validateQRScan()) {
      let selectedVenue = this.getVenueByDeliveryTypeTextCode(
        delivery.textCode
      );
      if (selectedVenue && selectedVenue.name) {
        return true;
      } else {
        this.forceUserSelectVenue(delivery.textCode);
        return false;
      }
    } else {
      return true;
    }
  }

  forceUserSelectVenue(deliveryType) {
    let modalToBeShown = this.venueSelectionModalToShow(deliveryType);
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(this.qrScanDetails?.venueName && selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == selectedLocation._id){
      this.sweetAlert.toast('error','Please try scanning qr once again.');
    } else if(deliveryType =='DELIVERYTYPE03'){
      this.sweetAlert.toast('error','Table not selected. Please try scanning qr to select table and place order.');
    } else 
    if (modalToBeShown) {
   

      this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
        (isMobileView) => (this.isMobileView = isMobileView)
      );
      if (this.isMobile) {
        this.modal.showModal(modalToBeShown);
      } else {
        this.modal.showMediumModalDesktop(modalToBeShown);
      }
    }
  }

  venueSelectionModalToShow(deliveryType) {
    switch (deliveryType) {
      case 'DELIVERYTYPE08':
        this.sweetAlert.toast(
          'error',
          'Please scan the QR placed in your lounge table to place the order'
        );
        return undefined;
      case 'DELIVERYTYPE09':
        this.sweetAlert.toast(
          'error',
          this.translate.instant('select-station-place-order')
        );
        return PickupLocationUpdateSelectionComponent;
      case 'DELIVERYTYPE10':
        this.sweetAlert.toast(
          'error',
          this.translate.instant('select-room-place-order')
        );
        return RoomUpdateSelectionComponent;
      default:
        break;
    }
  }



  getVenueByDeliveryTypeTextCode(deliveryType) {
    let selectedVenue;
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(this.qrScanDetails?.venueName && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == selectedLocation._id){
      selectedVenue = this.store.selectSnapshot(VenueManagementState.getSelectedVenue);
    } else {
      switch (deliveryType) {
        case 'DELIVERYTYPE08':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedVenue
          );
          break;
        case 'DELIVERYTYPE09':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedPickUpLocation
          );
          break;
        case 'DELIVERYTYPE10':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedRoom
          );
          break;
        default:
          break;
      }
    }
    return selectedVenue;
  }

  validateLocationGeoFencing() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    return new Promise(async (resolve) => {
      if (
        locationDefaults?.locationData?.orderSettings?.geoFencing?.isAllow &&
        locationDefaults?.locationData?.orderSettings?.geoFencing?.coordinates
          ?.length > 0
      ) {
        let isUserInsideGeoBoundary = undefined;
        isUserInsideGeoBoundary = await this.verifyUserInsideGeoBoundary(
          locationDefaults.locationData.orderSettings.geoFencing.coordinates,
          locationDefaults.locationData.orderSettings.geoFencing.scaling
        );
        if (isUserInsideGeoBoundary == false) {
          this.modal.showSmallModal(AlertPopupComponent, {
            data: {
              content: this.translate.instant(
                'user-not-inside-geoboundary-msg'
              ),
              actionable: false,
            },
            whenClosed: (data) => {},
          });
        }
        resolve(isUserInsideGeoBoundary);
      } else {
        resolve(true);
      }
    });
  }

  ClearAckrooPaymentDetails() {
    this.store.dispatch(new SetAckrooPaymentDetails(undefined));
  }
  ClearMealPaymentDetails() {
    this.store.dispatch(new SetMealPaymentDetails(undefined));
  }

  async verifyUserInsideGeoBoundary(boundaryCoordinates, scaling) {
    return new Promise(async (resolve) => {
      let filteredCoords = boundaryCoordinates.map((coord) => [
        coord.long,
        coord.lat,
      ]);
      let geoScaling = scaling ? scaling : 1.2;
      this.store.dispatch(new FetchCurrentCoordinates());
      const opts = { enableHighAccuracy: true };
      Geolocation.getCurrentPosition()
        .then((position) => {
          console.log('position', position);
          let coord = position['coords'];
          if (coord && coord['longitude'] && coord['latitude']) {
            let point = turf.point([coord['longitude'], coord['latitude']]);
            let polygon = turf.polygon([filteredCoords]);
            let scaledPoly = transformScale(polygon, geoScaling);
            let isInside = booleanPointInPolygon(point, scaledPoly, {
              ignoreBoundary: false,
            });
            resolve(isInside);
          }
        })
        .catch((err) => {
          this.modal.showSmallModal(AlertPopupComponent, {
            data: {
              content: 'Please enable location services to continue ordering',
              actionable: false,
            },
            whenClosed: (data) => {},
          });
        })
      //   this.getCurrentCoordinates$.pipe(
      //     takeUntil(this.destroy$)
      //   ).subscribe((coord) => {
      //     console.log(coord)
      //   if (coord && coord['longitude'] && coord['latitude']) {
      //     let point = turf.point([coord['longitude'], coord['latitude']]);
      //     let polygon = turf.polygon([filteredCoords]);
      //     let scaledPoly = transformScale(polygon, 1.5);
      //     let isInside = booleanPointInPolygon(point, scaledPoly, {
      //       ignoreBoundary: false,
      //     });
      //     resolve(isInside);
      //   }
      //  });
    });
  }

  removeDeal() {
    this.store.dispatch(new RemoveDeal());
  }

  clickExpressDeliveryOption(){
    this.enableExpressDeliveryOption = !this.enableExpressDeliveryOption;
    const activeCart = this.store.selectSnapshot(CartState.getCart);
    if (activeCart && activeCart.cart_id) {
      let body = {isExpressDeliveryOrder:this.enableExpressDeliveryOption}
      this.store.dispatch(new UpdateCartDetails(body,activeCart.cart_id));
    }
  }
  
  formatDeliveryCharge(text){
    if(Config.organizationId ==  '5f4d46e37e59e325b88dac4e')
    {
      switch(text){
        case 'Cafe Pickup' : {
          return  this.translate.instant('cafe-pickup');
        }break;
       
        case 'Classroom Delivery' : {
          return this.translate.instant('classroom-delivery');
        }break;
  
       default : return text;
        break;
      }
    }
    else{
      return text;
    }
  }

  checkOrderLevelModifiers(){
   if(this.orderSettings?.isAllowOrderLevelModifier){
    let modifiers = this.store.selectSnapshot(OrderLevelModifierState.getModifiers);
    if(modifiers && modifiers.length){
      let isSelected = modifiers.every(function(element, index) {
      //  console.log(element);
        if(element && element.isForced){
          if(element.isInCart){
            return true;
          }
          else{
            return false;
          }
  
        }
        else
          return true;
      });
  
     return isSelected;
    }
    else{
      return true;
    }
   }
   else{
     return true;
   }
    
   
  }

  fetchOrderLevelModifiers = ()=>{
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {menuProfileId: selectedLocation.activeProfile._id, deliveryType: selectedDelivery._id};
    this.store.dispatch(new FetchOrderLevelModifiers(data));
  }

  validateQRScan(){
    const qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(qrScanDetails?.venueName){
      if(qrScanDetails?.deliveryType == selectedDelivery.textCode && this.qrScanDetails.locationId == selectedLocation._id){
        return true;
      }
    } else {
      return true;
    }
    return false
  }

  isQRScanOrder(){
    if(this.qrScanDetails?.venueName && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType &&
      this.qrScanDetails.locationId == this.selectedLocation?._id){
        return true;
      }
      else
      return false;
  }
  
  checkOnlyCODExists(){
    if(this.activePaymentMethods?.length == 1 && this.activePaymentMethods[0].id == 5){
      return true;
    } 
    else return false;
  }

  public getShifts() {
    let selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const locationId = selectedLocation?._id;
    const orderType = this.delivery?.textCode;
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);

    let currTime = new Date();
    let selectedDate = new Date();
    if (futureDate) selectedDate = new Date(futureDate);
    let url =
      environment.mobileStoreApiUrl +
      'stores/location/' +
      locationId +
      '/shifts?type=' +
      orderType +
      '&currentTime=' +
      currTime.toISOString() +
      '&timezoneOffset=' +
      currTime.getTimezoneOffset();
    if (futureDate) {
      url =
        environment.mobileStoreApiUrl +
        'stores/location/' +
        locationId +
        '/shifts?type=' +
        orderType +
        '&currentTime=' +
        currTime.toISOString() +
        '&future=true&selectedTime=' +
        selectedDate.toISOString() +
        '&timezoneOffset=' +
        selectedDate.getTimezoneOffset();
    }
    this.store.dispatch(new FetchLocationStatus(url));
  }

 }
