<div class="cart-price-container">
  <div class="cart-price">
    <div class="item">{{ 'ItemTotal' | translate }}</div>
    <div class="price">
     <span *ngIf="cartPrice.subTotal">{{ cartPrice.subTotal | formattedCurrency }}</span> <span *ngIf="cartPrice && cartPrice.employeeLoyaltyPoints &&  cartPrice.employeeLoyaltyPoints.isEmployeeLoyalty && cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart"><span *ngIf="cartPrice.totalAmount">+</span>{{cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart}} PTS</span>
    </div>
    <div class="item" *ngIf="cartPrice.couponDiscount">
      {{ 'deal-discount' | translate }}
    </div>
    <div class="price" *ngIf="cartPrice.couponDiscount">
      -{{ cartPrice.couponDiscount | formattedCurrency }}
    </div>
    <ng-container *ngFor="let tax of cartPrice.taxes">
      <div class="item">{{ tax?.name }}</div>
      <div class="price">{{ tax?.value | formattedCurrency }}</div>
    </ng-container>

    <ng-container *ngIf="cartPrice && cartPrice.serviceFee">
      <ng-container *ngIf="cartPrice.serviceFee?.subtotal > 0">
        <div class="item platform-fees">
          {{ 'service-fee' | translate }}
          <button class="toggle" (click)="toggleFeesDetail()">{{showFeeString}}</button>
          <div class="fees-note" *ngIf="showFeeDetails">
            <div class="item" ><i>{{ 'note-platform-fee' | translate }}</i></div>
          </div>
        </div>
        <div class="price">
          {{ cartPrice.serviceFee.subtotal | formattedCurrency }}
        </div>
      
        <ng-container *ngIf="(cartPrice.serviceFee.total - cartPrice.serviceFee.subtotal) > 0">
          <div class="item">
            {{ 'service-fee' | translate }} {{ 'tax' | translate }} 
          </div>
          <div class="price">
            {{ (cartPrice.serviceFee.total - cartPrice.serviceFee.subtotal) | formattedCurrency }}
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="cartPrice && cartPrice.deliveryCharge">
    <div class="item" *ngIf="cartPrice.deliveryCharge?.price > 0">
      {{ formatDeliveryCharge(cartPrice.deliveryCharge.text)  }}
      {{ 'charges' | translate }}
      <button class="toggle" (click)="toggleDeliveryDetail()">{{showDeliveryString}}</button>
      <div class="fees-note" *ngIf="showDeliveryDetails">
        <div class="item" ><i>{{ 'note-delivery-fee' | translate }}</i></div>
      </div>
    </div>
    <div class="price" *ngIf="cartPrice.deliveryCharge?.price > 0">
      {{ cartPrice.deliveryCharge.price | formattedCurrency }}
    </div>
    <ng-container
      *ngIf="
        (cartPrice.deliveryCharge.price > 0 || cartPrice.deliveryCharge?.expressDeliveryCharge > 0) &&
        cartPrice.deliveryCharge.appliedTax > 0
      "
    >
      <div class="item">
        {{ formatDeliveryCharge(cartPrice.deliveryCharge.text) }} {{ 'tax' | translate }}
      </div>
      <div class="price">
        {{ cartPrice.deliveryCharge?.appliedTax | formattedCurrency }}
      </div>
    </ng-container>
  </ng-container>
    <div
      class="item"
      *ngIf="
        cartPrice &&
        cartPrice.promotionalDiscount &&
        cartPrice.promotionalDiscount > 0
      "
    >
      {{ 'PromotionalDiscount' | translate }}({{
        cartPrice.promotionalDiscountPercentage
      }}%)
    </div>
    <div
      class="price"
      *ngIf="
        cartPrice &&
        cartPrice.promotionalDiscount &&
        cartPrice.promotionalDiscount > 0
      "
    >
      -{{ cartPrice.promotionalDiscount | formattedCurrency }}
    </div>
  </div>
  <moduurnv2-manage-tip
    *ngIf="locationDefaults?.locationData?.orderSettings?.tips?.allowTip"
    [tipAmount]="cartPrice.tip"
    [selectedTip]="cartItems[0].tip"
    [avalibleTips]="locationDefaults.locationData.orderSettings.tips"
    (updateTip)="updateGrandTotal($event)"
    [subTotal]="cartPrice.subTotal"
  ></moduurnv2-manage-tip>
  <div class="delivery-speed options" *ngIf="locationDefaults.locationData.orderSettings.allowExpressDelivery && showExpressDeliveryOption">
    <!-- <div class="options-label"> -->
        <label class="delivery-speed-option options-label" aria-label="add delivery speed"
          >{{ 'enable-express-delivery' | translate }}
          <input
          type="checkbox"
          (click)="clickExpressDeliveryOption()"
          data-qa="enable-express-delivery"
          [checked]="enableExpressDeliveryOption"
          /> <span class="checkmark"></span> 
        </label>
    <!-- </div> -->
    <div class="price">{{ cartPrice.deliveryCharge.expressDeliveryCharge | formattedCurrency }}</div>
  </div>
  <ng-container *ngIf="enableCoupon">
    <div class="deals" *ngIf="selectedDeal?.promoCode; else applyCoupon">
      <div class="promoCode appliedCode">
        <img src="assets/images/Promo-Code-Active.svg" alt="promo code active"/>
        <p>PromoCode ({{ selectedDeal.promoCode }})</p>
        <!-- <p class="couponDiscount">
          -{{ cartPrice.couponDiscount | formattedCurrency }}
        </p> -->
        <button class="removeCoupon" (click)="removeDeal()">Remove</button>
      </div>
    </div>
    <ng-template #applyCoupon>
      <button class="promoCode" type="button" (click)="openDealsModal()">
        <img src="assets/images/Promo_code_icon.svg" alt="promo code"/>
        {{ 'apply-promo-code' | translate }}
      </button>
    </ng-template>
  </ng-container>
  <div class="total-container">
    <div class="text" aria-label="grand total">
      {{ 'grand-total' | translate }}
    </div>
    <div class="price">
      <span *ngIf="cartPrice.totalAmount"> {{ (cartPrice.totalAmount + (cartPrice?.serviceFee?.total ? cartPrice?.serviceFee?.total : 0)) | formattedCurrency }} </span>
      <span *ngIf="cartPrice && cartPrice.employeeLoyaltyPoints &&  cartPrice.employeeLoyaltyPoints.isEmployeeLoyalty && cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart"><span *ngIf="cartPrice.totalAmount">+</span>{{cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart}} PTS</span>
    </div>
  </div>
</div>
